<template>
  <div class="header-moblie">
    <div class="sg-header">
      <div class="logo-box">
        <img
          src="../assets/img/top_logo.png"
          alt=""
          srcset=""
          @click="$router.push({ name: 'Home' })"
        />
      </div>
      <div class="menu-box" v-show="!isShow" @click="changeNav(true)">
        <el-icon><operation /></el-icon>
      </div>
      <div class="menu-box" v-show="isShow" @click="changeNav(false)">
        <el-icon><close /></el-icon>
      </div>
    </div>
    <div class="topMenu nav-show" v-show="isShow">
      <ul>
        <li><router-link to="/homem">首页</router-link></li>
        <li><router-link to="/aboutm">公司介绍</router-link></li>
        <li><router-link to="/gamem">产品介绍</router-link></li>
        <li><router-link to="/joinm">加入我们</router-link></li>
        <li><router-link to="/contactm">联系我们</router-link></li>
        <li><span @click="goMycenter()">{{ username=='请登录'?'请登录':'个人中心'}}</span>
      <span v-show="isLogin" @click="logOut()">&nbsp;&nbsp; [ 退出 ]</span></li>
      </ul>
    </div>
  </div>
</template>

<script>
import "../assets/js/flexible";
import { mapActions, mapState } from "vuex"; //注册 action 和 state
import axios from "@/utils/axios";
import { ElMessage } from "element-plus";
import { Close, Operation } from "@element-plus/icons";
export default {
  name: "HeaderM",
  props: {},
  components: { Close, Operation },
  computed: {
    //在这里映射 store.state.userType，使用方法和 computed 里的其他属性一样
    ...mapState(["username", "isLogin"])
  },
  data() {
    return {
      isShow: false, // 是否显示菜单
      userInfo: {},
      logoImg: require("../assets/img/logowhite.png")
      // isLogin:false
    };
  },
  mounted() {
    let userInfoData = window.sessionStorage.getItem("userinfo");
    console.log("userInfoData", userInfoData != null,'isLogin',this.username);
    if (userInfoData != null) {
      // this.isLogin=true;
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
      this.$store.commit("changeUserType", this.userInfo.username);
      this.$store.commit("changeLoginStatus", true);
    } else {
      // this.isLogin=false;
      console.log("请登录");
      this.$store.commit("changeUserType", "请登录");
      this.$store.commit("changeLoginStatus", false);
    }

  },
  methods: {
    changeNav(type){
      this.isShow=type;
    },
    goMycenter() {
      if (this.username!='请登录') {
        this.$router.push({ name: "MycenterM" });
      } else {
        this.$router.push({ name: "LoginM" });
      }
    },
    logOut() {
      let token = window.sessionStorage.getItem("token");
      this.userInfo = JSON.parse(window.sessionStorage.getItem("userinfo"));
      axios.post("/logout", { token: token, username: this.userInfo.username }).then(res => {
        if (res.code == 1) {
          window.sessionStorage.removeItem("userinfo");
          window.sessionStorage.removeItem("token");
          this.$store.commit("changeUserType", "请登录");
          this.$store.commit("changeLoginStatus", false);
          this.$router.push({ name: "Home" });
          ElMessage({
            message: "退出登录成功",
            type: "success"
          });
        } else if (res.code == 0) {
          ElMessage({
            message: res.msg,
            type: "error"
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header-moblie {
  .sg-header {
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: 30;
    display: flex;
    justify-content: space-between;
    border-bottom: #eee 1px solid;
    .logo-box {
      width: 80%;
      img {
        min-width: 50px;
        height: 30px;
        display: block;
        padding-top: 20px;
        padding-left: 20px;
      }
    }
    .menu-box {
      width: 30px;
      height: 30px;
      margin-top:16px;
      padding-right: 20px;
      .el-icon {
        color: #666;
      }
    }
  }
  .topMenu {
    position: fixed;
    top: 0;
    background: rgba(255, 255, 255, 0.92);
    z-index: 20;
    height: 100%;
    width: 100%;
    ul {
      list-style: none;
      margin: 0;
      padding: 60px 16px 0;
      li {
        list-style: none;
        border-bottom: 1px solid #eeeeee;
        text-align: center;
        a,span {
          color: #3a3d40;
          text-decoration: none;
          cursor: pointer;
          border: none;
          display: block;
          font-weight: bold;
          font-size: 16px;
          line-height: 70px;
        }
        span{
          display: inline-block;
        }
      }
    }
  }
}
</style>
